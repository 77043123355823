import defaultTheme from './default';

export default `
  :host {
    ${defaultTheme}
  }

  :host .footer .poweredby img {
    filter: brightness(var(--disclaimer-logo-brightness, 100%));
  }

  :host slot[name='disclaimer'] footer {
    padding-top: 20px;
    margin-top: -10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`;
